import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

function PrivacyPolicy() {

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({top: 0})
    }, [])

    return (
        <div className={"conditions"}>
            <p><strong>PRIVACY POLICY</strong></p>
            <p>ViaRest Mobile Limited respects your privacy. ViaRest Mobile Limited and its affiliates (“ViaRest” or the “Company”) offer mapping, GPS navigation, community based services and Carpool services to users of ViaRest’s mobile applications (the “Application”) (collectively referred to as the “Services”). When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.</p>
            <p>This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, and delete your information. This Privacy Policy covers:</p>
            <ul>
                <li>User Accounts &amp; Accessing the Services</li>
                <li>Information that is being collected</li>
                <li>Why ViaRest collects data</li>
                <li>Sharing your information</li>
                <li>Visibility</li>
                <li>ViaRest Carpool Profile</li>
                <li>Integration with social networks</li>
                <li>Integration with other third party services</li>
                <li>Controlling your personal information</li>
                <li>How ViaRest retains data we collect</li>
                <li>How does ViaRest use aggregated information</li>
                <li>Cookies</li>
                <li>Advertising campaigns</li>
                <li>Information security</li>
                <li>Compliance &amp; cooperation with regulators</li>
                <li>Changes to this Privacy Policy</li>
            </ul>
            <p>This Privacy Policy is incorporated as part of the Waze Terms of Use and Waze Carpool Terms of Use (the “Terms of Use” or the “Terms”). <strong>Your use of the Services is subject to the Terms of Use, Copyright Policy and this Privacy Policy and indicates your consent to them.</strong></p>
            <p>To use our Services you must be 16 years of age or older. If you are under 16, you may not download or use the Services.</p>
            <p>In this Privacy Policy, the term “personal information” means information which you provide to us which personally identifies you, such as your name, email address or billing information, and, in certain circumstances, your location and route information or other data which can be reasonably linked to such information by ViaRest.</p>
            <p>You can adjust your <strong>Privacy Settings</strong> in the ViaRest app and choose which personal information we may collect about you and how your information is used.</p>
            <p><strong>Contact ViaRest - Privacy concerns reports</strong></p>
            <p>If you have any questions about this Privacy Policy, or if you believe that your privacy has been compromised in the course of using the Services, please contact ViaRest at: ios@ndsas.sk.</p>
            <p><strong>User Accounts &amp; Accessing the Services</strong></p>
            <p>Please note that when you use our Services , ViaRest will set up an account (the “Account” or “ViaRest Account”). ViaRest will collect and use your information, including meta-data (as described below), in accordance with this Privacy Policy. This is the case whether or not you choose to actively register your Account, for example, by adding your email address or logging with your Google Account . ViaRest may allow you to use the Services whether or not you choose to register your Account. If you choose to use the ViaRest Services without registering your account you may do so by skipping the registration process. If you do so:</p>
            <ul>
                <li>ViaRest will still link all of your information with the non-registered Account and a unique identifier generated by ViaRest in accordance with this Privacy Policy. This helps us do things like find you the best route.</li>
                <li>ViaRest may allow you to connect your non-registered account with other devices and browsers (for example by using a QR code) and, if you choose to do so, information collected from these devices and browsers will be linked to your non-registered account.</li>
                <li>With the exception described in the bullet below, and as explained in the section “Visibility” below, your Account and the information associated with your Account (including your unique identifier) will be visible only to ViaRest; and</li>
                <li>When using the Services, your approximate location and other information you may choose to contribute will be visible to other users associated with the general username “ViaRest”.</li>
                <li>Note that in order to access certain features of the Services (for example map editing, ViaRest Carpool, ranking, moods) you will need to register your account.</li>
            </ul>
            <p>If you do register your Account:</p>
            <ul>
                <li>ViaRest will link all of your information with your registered Account. This includes information received from all devices and browsers which you have chosen to sign in ;</li>
                <li>With the exception described in the bullet below and as explained in the section “Visibility” below, your Account and information associated with your Account will be visible only to ViaRest; and</li>
                <li>When using the Services, your approximate location and other information you may choose to contribute will be visible to other users associated with this Account.</li>
                <li>ViaRest may also establish and require from time to time additional or different means of identification and authentication for logging in and accessing the Services or for accessing certain features or designated sections of the Services.</li>
                <li>Your login details are your responsibility. You are fully accountable for any use or misuse of your Account and personal details as a result of conveying your login details to someone else. You must maintain your login details in absolute confidentiality and avoid disclosing them to others. If you set a password, make sure that you change your password frequently and at least once every six months.</li>
            </ul>
            <p><strong>Information that is being collected</strong></p>
            <p><strong>We want you to understand the types of information we collect as you use our services</strong></p>
            <p>ViaRest collects information to provide better services to all its users - from figuring out stuff like what’s the best route, to more complex things like monitoring traffic so you know when to leave, and showing you relevant ads while using ViaRest. The information ViaRest collects, and how that information is used, depends on how you choose to use our services and how you manage your privacy controls.</p>
            <p><strong>Information you create or provide to us:</strong></p>
            <ul>
                <li><strong>Account Information:</strong> information required when setting up an Account, such as your name, email address or information required to create a Carpool profile (if you choose to do so). Bear in mind that false, incorrect, or out-dated information may impair ViaRest’s ability to provide you with the Services and to contact you when necessary. ViaRest will explicitly indicate the fields for mandatory completion. If you do not enter the requisite data in these fields, you may not be able to register your Account or to be provided with certain features, such as ViaRest Carpool (but you may still use our Services as explained in the above section “User Accounts &amp; Accessing the Services”)</li>
            </ul>
            <p><strong>Information we collect as you use our services</strong></p>
            <p>We collect information about your activity in the Services, which we use to provide you with the Services of ViaRest, including features which are meant to personalize your experience. This includes things like finding you the preferred route and proposing favorites or destinations commonly traveled by you to streamline your search and travel and, if you use ViaRest Carpool - to match you with other drivers or riders. The activity information we collect may include:</p>
            <ul>
                <li><strong>Detailed location, travel and route information</strong>. Location and route information is collected for example in the form of GPS signals (combined with a time-stamp), IP address and other information from sensors and receivers on or around your device, on which the Application is installed and activated. This location and route information is saved to a route history of all of the journeys you have made while using the Application. The types of location data we collect depends in part on your device and account settings. For example, depending on your selected device settings and/or your account settings we may collect information about your location and travel (or lack thereof) also while you are not using the app, in order to be able to tell you when to leave for planned drives, notify you about bad traffic in your area, provide you parking assistance, analyze how effective our ads are and more;</li>
                <li>Terms or venues you searched;</li>
                <li>Your views and interactions with content and ads;</li>
                <li>Your voice and audio information when you use voice and audio features;</li>
                <li>Communication or other content you chose to share with any third party, including ViaRest users through the Services;</li>
                <li>Your activity on third-party sites and apps that you chose to link to your ViaRest account or that you interact with through the Service.</li>
                <li>Your activity as a ViaRest Carpool’s rider or driver, such as users you interacted with and rating that you have received from other users.</li>
            </ul>
            <p><strong>Meta-data (information about your device, browser and app use):</strong></p>
            <p>ViaRest collects information about the use of the Services and information from the device you have ViaRest installed on or the browser from which you use our Services. For example:</p>
            <ul>
                <li>ViaRest may collect and record how often you use our Services and for how long, your device type, unique identifiers, operating system type &amp; version, battery usage, the third party web pages or applications you visit or use or interact with via the Application, information that you viewed on our Services, advertisements that are shown to you or that you view or click on, the fact that you used the Services to communicate with other users and the fact that you used third parties application/services via ViaRest to communicate with third parties, and the duration of such communications, the Internet Protocol (IP) address and the name of the domain that serves you to access the Services, and the geographic location of the device that you are using to log in and use ViaRest on.</li>
                <li>ViaRest may also collect information about apps installed on your device that ViaRest can link with - such as third party music or media audio service apps (e.g. Spotify).</li>
                <li>We also collect information about the interaction of your devices with our Services, including, crash reports, system activity, and the date, time, and referrer URL of your request.</li>
            </ul>
            <p><strong>Information we collect about you from our partners</strong></p>
            <p>We work with ad partners, including advertisers on our platform and entities that belong to the Google group of companies, in order to show you more relevant and useful ads based on your ViaRest activity and information associated with your device’s identifier (i.e, Advertising identifiers or similar technologies) shared with us by our ad partners. Your device’s identifier can be managed in your device’s settings.</p>
            <p>You can decide whether we’ll show you personalized ads or not through your in-app <strong>Privacy Settings</strong>–<strong>Ads personalization</strong>. Note: turning Ads personalization off does not turn off ads - only their personalization.</p>
            <p>We may also receive aggregated, non-personally identifiable information and analysis from third parties measurement services which help us understand how our users interact with our ads. We may also receive information from third party services about how ViaRest’s own marketing efforts performed on third party sites or platforms.</p>
            <p>We use various technologies to collect and store information, including, cookies, Advertising ID available on Android mobile devices or similar technologies, local storage, browser web storage, application data caches, databases, and server logs.</p>
            <p><strong>Why ViaRest collects data</strong></p>
            <p><strong>ViaRest may use information collected from or provided by you for the following purposes:</strong></p>
            <p><strong>Provide our services</strong></p>
            <p>We use your information to deliver our services. For example:</p>
            <ul>
                <li>Use your location and route information and/or search query history to provide navigation services, best route choices, traffic alerts and to provide information about sites, shops and other places and attractions in your close vicinity; and</li>
                <li>(If you have opted in to the “find friends” feature), use your phone number and the phone numbers which are stored in your device’s phone book to find and create a list of other ViaRest users who you may know and with whom you may wish to connect.</li>
                <li>To share your submissions with other users of the Services and to facilitate communication between you, ViaRest and other users.</li>
                <li>If you joined ViaRest Carpool, use your information, such as home, work and schedule, to find you potential carpoolers for your ride;</li>
            </ul>
            <p><strong>Maintain &amp; improve our services</strong></p>
            <ul>
                <li>We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting issues you report to us. And we use your information to improve the service or to develop new features or services. For example, ViaRest may use your route information to improve the accuracy of its mapping and navigation data.</li>
            </ul>
            <p><strong>Provide personalized services, including content and ads</strong></p>
            <ul>
                <li>We use the information we collect to operate the Services and to customize your experience with the Services. For example, ViaRest may use your “home” and “work” and other “favorites” information (if you choose to provide such information) or route history to offer routes based on how you’ve chosen to travel between favorite destinations in the past. Based on your past activity on the Services we may also suggest to you drive predictions and common destinations where you use the app to navigate to and personalize your ETA.</li>
                <li>We may also use the information to provide you with support and handle requests and complaints.</li>
            </ul>
            <p><strong>Measure performance</strong></p>
            <ul>
                <li>We use data for analytics and measurement to understand how our services are used. For example, we analyze data about your usage of the Services to do things like optimize product design. We also use the data we collected about the ads you view or interact with (e.g. click on) to help advertisers understand the performance of their ad campaigns and to provide them with aggregated reports. We may also use data to measure the usability and effectiveness of ViaRest’s own marketing efforts on third party sites or platforms.</li>
            </ul>
            <p><strong>Communicate with you</strong></p>
            <ul>
                <li>We also use your information to send you updates, notices, announcements, users’ requests and additional information related to the Services (including through push notifications and email address);</li>
                <li>We may also use your information to conduct surveys and questionnaires; And if you contact ViaRest we may keep a record of your request in order to help solve any issues you might be facing.</li>
                <li>Subject to your prior indication of consent (to the extent required under applicable law), we may also use the email address that you provided to send you promotional and/or marketing materials. At any time you may choose not to receive such materials by clicking on the “Unsubscribe” link in the email messages that we may send to you. In any case, ViaRest will not share your contact details with any advertiser, unless explicitly permitted or required by law.</li>
            </ul>
            <p><strong>To protect ViaRest, our users, the public and for Legal reasons</strong></p>
            <p>We may also use and process your information and content, using different technologies to the following purposes:</p>
            <ul>
                <li>To enforce the Terms of Use or Privacy Policy;</li>
                <li>To contact you when ViaRest believes it to be necessary;</li>
                <li>To comply with any applicable law and assist law enforcement agencies under any applicable law, when ViaRest has a good faith belief that ViaRest’s cooperation with the law enforcement agencies is legally mandated or meets the applicable legal standards and procedures;</li>
                <li>To detect abuse and illegal activity; to detect and prevent fraud, misappropriation, infringements, identity theft and other illegal activities and misuse of the Services;</li>
                <li>To handle breakdowns and malfunctions;</li>
                <li>To take any action in any case of dispute, or legal proceeding of any kind between you and the Services, or between you and other users or third parties with respect to, or in relation with the Services;</li>
                <li>For purposes provided under this Privacy Policy and Terms of Use;</li>
                <li>To help improve the safety and reliability of our Services. This includes detecting, preventing, and responding to security risks, and technical issues that could harm ViaRest, our users, or the public;</li>
                <li>To create aggregated and/or anonymous data (where such data does not enable the identification of a specific user). See “How does ViaRest use aggregated information”, below;</li>
            </ul>
            <p>We’ll ask for your consent before using your information for a purpose that isn’t covered in this Privacy Policy.</p>
            <p><strong>Sharing your information</strong></p>
            <p><strong>Personal information that you share</strong></p>
            <p>The Services are based on a community of users publicly sharing information with ViaRest and with other members of that community.</p>
            <p>In particular, users may choose to share personal information such as name, age, gender, picture, their location information, reports and other files. This information may be shared either directly to the Services and to all users, such as in the case of traffic reports, or just to your friends on ViaRest. This information may also be shared via third party services (such as social networks) which interact with the Services upon such users’ choice. For more information about your Carpool profile (if you choose to set one).</p>
            <p><strong>You can decide whether or not to upload content to the Services. However any content that you choose to share and submit for posting is neither private nor confidential and you should expect that it may be made public and known to others.</strong> Information you choose to upload will be posted along with your user name and other information including personal information you attached to your publicly available user profile. For example, your reports (map and road updates, traffic congestion updates, road accidents, etc.) will be posted on the ViaRest map along with your username. You should note that certain road incidents you report may include your driving path within the vicinity of those incidents. Therefore, you must exercise caution and common sense when submitting information. At the very least you should demonstrate the same degree of caution as when publishing personal information by means other than the Internet and cellular services.</p>
            <p>In any event (but except as detailed below with respect to information shared from your social network accounts according to your privacy settings for such social network accounts), your email address will not be visible to others through any reports or user posts that you share or upload.</p>
            <p>if you choose to either record your own voice onto the Service, or to use the voice command features, ViaRest will collect the voice information related to such use, including voice files. If, after recording your own voice, you chose to share your voice recordings, other ViaRest users and other people will be able to download and use your voice recordings. Once downloaded, ViaRest may not have any control over the voice files, so you should only share content that you are comfortable being made public.</p>
            <p>We, at ViaRest, take great measures to provide you with the best experience you can have while using the Services. However, ViaRest has no control over the conduct of any user and disclaims all liability in this regard. Users are advised to carefully and thoroughly consider whether or not to make public or available any information and carefully examine all necessary details related to any communication with other users prior to any engagement or communication being made.</p>
            <p>Participating in any user’s activities as a result, directly or indirectly, from using ViaRest, is entirely at your own risk. ViaRest is not a party to any agreement entered into between the users in any circumstances. The user has the sole and ultimate responsibility regarding compliance with all laws, regulations or any other duty. We do not accept any liability for any loss, damage, cost or expense that you may suffer or incur as a result of or in connection with your participation in any activity or event initiated, held or conducted by a user or a third party nor in connection to any agreement between the users or third parties, including any activity or event related in any way, directly or indirectly, the Services or the use thereof.</p>
            <p><strong>When ViaRest shares your information</strong></p>
            <p>ViaRest does not sell, rent or lease your personal information to third parties.</p>
            <p>ViaRest will not share your personal information with others, without your permission, except for the following purposes and to the extent necessary in ViaRest’s good-faith discretion:</p>
            <ul>
                <li>As necessary for the facilitation and operation of the Services. For example, making public road incidents you report and which are associated with your username and your location;</li>
                <li>If ViaRest reasonably believes that you have breached the Terms of Use or is investigating potential breach of the Terms of Use, or believes that you abused your rights to use the Services, or performed any act or omission that ViaRest reasonably believes to be violating any applicable law, rules, or regulations. ViaRest may share your information in these cases, with law enforcement agencies and other competent authorities and with any third party as may be required to handle any result of your wrongdoing;</li>
                <li>If ViaRest is required, or reasonably believes that it is required by law to share or disclose your information;</li>
                <li>In any case of dispute, or legal proceeding of any kind between you and ViaRest, or between you and other users with respect to, or in relation with the Services;</li>
                <li>In any case where ViaRest reasonably believes that sharing information is necessary to protect against harm to the rights, property or safety of ViaRest, our users, or the public as required or permitted by law, including to prevent imminent physical damage or damage to property;</li>
                <li>To detect, prevent, or otherwise address fraud, security, or technical issues;</li>
                <li>If ViaRest organizes the operation of the Services within a different framework, or through another legal structure or entity, or if ViaRest is acquired by, or merged into or with another entity, or if ViaRest enters bankruptcy, provided however, that those entities agree to be bound by the provisions of this Privacy Policy, with respective changes taken into consideration;</li>
                <li>To collect, store, hold and manage your personal information through cloud based or hosting services or a third party or a party affiliated or connected to ViaRest, as reasonable for business purposes, which may be located in the European Union and the U.S.A., potentially countries outside of your jurisdiction;</li>
                <li>ViaRest may also share personal information with companies or organizations connected or affiliated with ViaRest, such as subsidiaries, sister-companies and parent companies (i.e. entities that belong to the Google group of companies);</li>
                <li>We work with other Google’s services (such as Google Display and Video 360) for advertising and ad measurement purposes. In order to help advertisers decide what ads to show on ViaRest, Google, and websites &amp; apps that partner with Google, we may share information with these services, such as a unique identifier for advertising (i.e, Advertising ID available on Android mobile devices or similar technologies).</li>
                <li>We also work with selected advertising and measurement services which help us understand how our users interact with ads served in our platform and in order to provide marketing, advertising and measurement services for ViaRest with respect to ViaRest’s own marketing efforts, by using and sharing devices’ identifiers (i.e, Advertising ID, cookies or similar technologies).</li>
                <li>Personal information may also be shared with ViaRest’s trusted partners and service providers to process it for us, based on our instructions and in compliance with this policy and any other appropriate confidentiality and security measures. For example, we use service providers to help us with customer services.</li>
            </ul>
            <p><strong>Visibility</strong></p>
            <p>Please note that your username and some other information will not be visible to others.</p>
            <p><strong>Controlling your personal information</strong></p>
            <p>You can always review and update certain information by visiting your Account &amp; Login settings (such as your phone, name and more) or the in-app menu (to update your favorites). </p>
            <p>If you find that the information associated with your Account is not accurate, complete or updated, then you should make all necessary changes to correct it. Please keep in mind that false, incorrect, or outdated information may prevent you from setting up an Account and impair the ability to provide you with Services.</p>
            <p><strong>Privacy controls</strong></p>
            <p>Your in-app Privacy Settings provide you with quick access to settings and tools that let you safeguard your data and decide how your information can make ViaRest services work better for you.</p>
            <p>There are other ways to control the information ViaRest collects whether or not you’re signed in to a ViaRest Account, including device-level settings: your device may have controls that determine what information we collect. For example, you can modify location settings on your device.</p>
            <p><strong>Deleting your information</strong></p>
            <p>If you would like us to delete your Account, please use the “Delete Account” function available in the app.</p>
            <p><strong>How ViaRest retains data we collect</strong></p>
            <p>Some data you can delete whenever you like, some data is deleted automatically, and some data we retain for longer periods of time when necessary. This section describes why we hold onto different types of data for different periods of time.</p>
            <ul>
                <li><strong>Information retained until you remove it</strong> - such as your “Home, “work”, favorites, email address and other account related information. We’ll keep this data in your ViaRest Account until you choose to remove it and/or update it.</li>
                <li><strong>Data that expires after a specific period of time</strong> - in some cases, you don’t need to actively delete your information as we set retention timeframes based on the reason for the data’s collection. For example, if you choose to connect your calendar to your ViaRest Account, we extract your calendar events for several days in advance and delete them after their occurrence. We may also take steps to anonymize certain data within set timeperiods.</li>
                <li><strong>Information retained until your ViaRest Account is deleted</strong> - we may keep some data for the life of your ViaRest Account if it’s useful for helping us understand how users interact with our features and how we can improve our services. For example, if you made an incident report, we will keep information about how many reports you have submitted, even after the event is removed. When you delete your ViaRest Account, the information about how many reports you have submitted is also removed.</li>
                <li><strong>Information retained for extended time periods for limited purposes</strong> - sometimes business and legal requirements oblige us to retain certain information, for specific purposes, for an extended period of time. For example, when ViaRest processes a payment for you, we’ll retain this data for longer periods of time as required for tax or accounting purposes. Reasons we might retain some data for extended periods of time include:<ul>
                    <li>Security, fraud &amp; abuse prevention</li>
                    <li>Financial record-keeping</li>
                    <li>Complying with legal or regulatory requirements</li>
                    <li>Ensuring the continuity of our services</li>
                    <li>Direct communications with ViaRest</li>
                </ul>
                </li>
            </ul>
            <p><strong>How does ViaRest use aggregated information?</strong></p>
            <p>ViaRest may use anonymous, statistical or aggregated information (including anonymous location information), in a form that does not enable the identification of a specific user, to properly operate the Services, to improve the quality of the Services, to enhance your experience, to create new services and features, including customized services, to change or cancel existing content or service, and for further internal, commercial and statistical purposes (including advertising).</p>
            <p>ViaRest may also use anonymous, statistical or aggregated information collected on the Services, in a form that does not enable the identification of a specific user, by posting, disseminating, transmitting or otherwise communicating or making available such information to users of the Services, to the Services’ providers, partners and any other third party.</p>
            <p><strong>Information security</strong></p>
            <p>ViaRest considers information security to be a top priority. ViaRest services are built with strong security features that continuously protect your information. ViaRest implements systems, applications and procedures to secure your personal information, to minimize the risk of theft, damage, loss of information, or unauthorized access or use of information. For example, we review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems. In addition, we restrict access to personal information to ViaRest employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.</p>
            <p>The insights we gain from maintaining our services help us detect and automatically block security threats from ever reaching you.</p>
            <p>However, these measures are unable to provide absolute assurance. Therefore, although ViaRest takes great efforts to protect your personal information, ViaRest cannot guarantee and you cannot reasonably expect that ViaRest’s databases will be immune from any wrongdoings, malfunctions, unlawful interceptions or access, or other kinds of abuse and misuse.</p>
            <p><strong>Compliance &amp; cooperation with regulators</strong></p>
            <p>We regularly review this Privacy Policy and make sure that we process your information in ways that comply with it.</p>
            <p><strong>Data transfers</strong></p>
            <p>We store data on servers located around the world and your information may be processed on servers located outside of the country where you live. Data protection laws vary among countries, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this policy.</p>
            <p>When we receive formal written complaints, we respond by contacting the person who made the complaint. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of your data that we cannot resolve with you directly.</p>
            <p><strong>European requirements</strong></p>
            <p>If European Union (EU) or United Kingdom (UK) data protection law applies to the processing of your information, we provide the controls described in this policy so you can exercise your right to request access to, update, remove, delete, and restrict the processing of your information.</p>
            <p>You can also export a copy of data in your ViaRest account if you want to back it up or export your information to another service. You also have the right to object to the processing of your information.</p>
            <p>We process your information for the purposes described in this policy, based on the following legal grounds:</p>
            <p><em>With your consent</em></p>
            <p>We ask for your agreement to process your information for specific purposes and you have the right to withdraw your consent at any time. You can manage your settings at any time, including your in-app Privacy Settings.</p>
            <p><em>When we<strong>’</strong>re pursuing legitimate interests</em></p>
            <p>We process your information for our legitimate interests and those of third parties (such as partners including advertisers, in order to report use statistics) while applying appropriate safeguards that protect your privacy. This means that we process your information for things like:</p>
            <ul>
                <li>Providing, maintaining, and improving our services to meet the needs of our users</li>
                <li>Developing new services and features that are useful for our users</li>
                <li>Understanding how people use our services (including how they interact with our ads) to ensure and improve the performance of our services</li>
                <li>Customizing our services to provide you with a better user experience</li>
                <li>Marketing to inform users about our services</li>
                <li>Providing advertising, which keeps many of our services free</li>
                <li>Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our services</li>
                <li>Protecting against harm to the rights, property or safety of ViaRest, our users, or the public as required or permitted by law, including disclosing information to government authorities</li>
                <li>Performing research that improves our services for our users and benefits the public</li>
                <li>Fulfilling obligations to our partners like developers and rights holders</li>
                <li>Enforcing legal claims, including investigation of potential violations of applicable Terms of Service</li>
            </ul>
            <p><em>When we<strong>’</strong>re providing a service</em></p>
            <p>We process your data to provide a service you’ve asked for under a contract (including our terms of use).</p>
            <p><em>When we<strong>’</strong>re complying with legal obligations</em></p>
            <p>We’ll process your data when we have a legal obligation to do so, for example, if we’re responding to legal process or an enforceable governmental request.</p>
            <p>If you have questions, you can contact ViaRest and our data protection office at: ios@ndsas.sk. And you can contact your local data protection authority if you have concerns regarding your rights under local law.</p>
            <p><strong>Changes to this Privacy Policy</strong></p>
            <p>ViaRest may from time to time change the terms of this Privacy Policy. Substantial changes will take effect 30 days after ViaRest has posted a:</p>
            <ul>
                <li>System message to users notifying them of changes to the Privacy Policy; and</li>
                <li>Notification on the Website’s homepage or any other relevant web pages, explaining the changes to the Privacy Policy alongside a comparison of the “old” and “new” versions of the Privacy Policy.</li>
            </ul>
            <p>Other changes will take effect 7 days after their initial posting on the Website. However, if ViaRest amends this Privacy Policy to comply with legal requirements, the amendments will become effective immediately upon their initial posting, or as required.</p>
            <p>You agree to be bound by any of the changes made in the terms of this Privacy Policy. Continuing to use the Services will indicate your acceptance of the amended terms. If you do not agree with any of the amended terms, you must avoid any further use of the Services.</p>
            <p><strong>Last modified:</strong> <strong>3 March 2022</strong></p>

            <button className={"button"} onClick={() => navigate("/")}>Naspäť</button>
        </div>
    );
}

export default PrivacyPolicy;
