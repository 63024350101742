import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Main from "./components/main";
import TermsOfConditions from "./components/terms-of-conditions";
import PrivacyPolicy from "./components/privacy-policy";
import Copyright from "./components/copyright";
// @ts-ignore
import HttpsRedirect from 'react-https-redirect';

function App() {


  return (
      <HttpsRedirect>
          <BrowserRouter>
              <Routes>
                  <Route path="" element={<Main />} />
                  <Route path="terms-and-conditions" element={<TermsOfConditions />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="copyright" element={<Copyright />} />
                  <Route path="*" element={<Navigate to={"/"} />} />
              </Routes>
          </BrowserRouter>
      </HttpsRedirect>
  );
}

export default App;
